const fakeNewsService = {
    getNews: async function () {
        return Promise.resolve([
            
            {
                title: "Herrensitzung 2025",
                image: "images/Plakat-HS-2025.png",
                content: "Wann: Sonntag 23.02.2025 ab 13:30\n\r" +
                    "Wo: Festzelt am Marktplatz Opladen\n\r",
                files: [{
                    title: "Herrensitzung 2025",
                    src: "files/Plakat HS 2025 A4.pdf"
                }]
            },
            {
                title: "Kindersitzung 2025",
                image: "images/Kindersitzung-2025.png",
                content: "Wann: Sonntag 09.02.2025 ab 13:00\n\r" +
                    "Wo: Scala Club Opladen\n\r",
                files: [{
                    title: "Kindersitzung 2025",
                    src: "files/Plakat KS 2025 A4.pdf"
                }]
            },
            {
                title: "Feldmesse 2025",
                image: "images/Feldmesse-2025.png",
                content: "Wann: Samstag 08.02.2025 ab 18:00\n\r" +
                    "Wo: St. Elisabeth Kirche\n\rKölner Str. 139, 51379 Leverkusen-Opladen",
                files: [{
                    title: "Feldmesse 2025",
                    src: "files/Feldmesse 2025 A4.pdf"
                }]
            },
            {
                title: "Jünemann's Ovend 2025",
                image: "images/Plakat-JO-2025.png",
                content: "Wann: Samstag 25.01.2025 ab 17:30\n\r" +
                    "Wo: Stadthalle Bergisch Neukirchen\n\r",
                files: [{
                    title: "Jünemann's Ovend 2025",
                    src: "files/Plakat JO 2025 A4.pdf"
                }]
            },
            {
                title: "Klapp Flyer 2025",
                image: "images/NeustadtFunkeTrans.png",
                content: "Beachtet gerne unseren aktuellen Klapp Flyer!\n\r" +
                    "Dieser fasst Informationen über uns und unsere Veranstaltungen für das Jahr 2025 zusammen.",
                files: [{
                    title: "Klapp Flyer2025",
                    src: "files/Klappflyer A4 24_25.pdf"
                }]
            },

            {
                title: "Herrensitzung 2024",
                image: "images/Plakat-HS-2024.jpeg",
                content: "Wann: Sonntag 04.02.2024 ab 13:30\n\r" +
                    "Wo: Festzelt am Marktplatz Opladen\n\r",
                files: [{
                    title: "Herrensitzung 2024",
                    src: "files/Plakat HS 2024 A4.pdf"
                }]
            },
            {
                title: "Abend unter Freunden 2024",
                image: "images/Plakat-AuF-2024.jpeg",
                content: "Wann: Samstag 27.01.2024 ab 17:30\n\r" +
                    "Wo: Stadthalle Bergisch Neukirchen, Wuppertalstr. 8, 51381 Leverkusen\n\r",
                files: [{
                    title: "Abend unter Freunden 2024",
                    src: "files/Plakat AuF 2024 A4.pdf"
                }]
            },
            {
                title: "Kindersitzung 2024",
                image: "images/Plakat-KS-2024.jpeg",
                content: "Wann: Sonntag 21.01.2024 ab 13:00\n\r" +
                    "Wo: Scala Club Opladen, Uhlandstr. 9, 51379 Leverkusen\n\r",
                files: [{
                    title: "Kindersitzung 2024",
                    src: "files/Plakat KS 2024 A4.pdf"
                }]
            },
            {
                title: "Klapp Flyer 2024",
                image: "images/NeustadtFunkeTrans.png",
                content: "Beachtet gerne unseren aktuellen Klapp Flyer!\n\r" +
                    "Dieser fasst Informationen über uns und unsere Veranstaltungen für das Jahr 2024 zusammen.",
                files: [{
                    title: "Klapp Flyer2024",
                    src: "files/Klappflyer-a4-2024.pdf"
                }]
            },
            {
                title: "Sommerfest der KG Neustadtfunken 2023",
                image: "images/Sommerfest2023.jpeg",
                content: "Wann: 26.08.23 ab 14 Uhr\n\r" +
                    "Wo: Gelände der Steppenwölfe in der Daimlerstr. 2b, 51381 Leverkusen",
                files: [{
                    title: "Sommerfest 2023",
                    src: "files/Sommerfest2023.pdf"
                }]
            },
            {
                title: "Oktoberfest der KG Neustadtfunken 2023",
                image: "images/Oktoberfest2023.jpeg",
                content: "In traditioneller Tracht und zu bayrischen Schmankerl und leckerem Oktoberfestbier wollen wir wieder mit Euch zusammen schunkeln, singen und schwaden.\n\r\n\r" +
                    "Wann: 02.10.23 ab 15 Uhr\n\r" +
                    "Wo: Im Funkenkeller, 51379 Leverkusen",
                files: [{
                    title: "Oktoberfest 2023",
                    src: "files/Oktoberfest2023.pdf"
                }]
            },
            {
                title: "Karneval Parties im Funkenkeller 2023",
                image: "images/Funkenkeller20230216.png",
                content: "Große Feiern im Funkenkeller!\n\rZu Weiberfastnacht und Rosenmontag lassen wir in unserem Funkenkeller die Jecken tanzen.\n\r\n\r" +
                    "Weiberfastnacht am 16.2.2023 ab 17:00\n\r" +
                    "Rosenmontag am 20.2.2023 ab 15:00\n\r" +
                    "Wo: Funkenkeller, Birkenbergstraße 33, 51379 Leverkusen-Opladen\n\r",
                files: [{
                    title: "Funkenkeller20230216",
                    src: "files/Funkenkeller20230216.pdf"
                }]
            },
            {
                title: "Kindersitzung 2023",
                image: "images/Kindersitzung20230205.png",
                content: "Besucht unsere Kindersitzung im Februar 2023!\n\r\n\r" +
                    "Unser Kinderprinzenpaar Linnéa I. und Prinz Lennard I. laden Euch herzlich ein.\n\r\n\r" +
                    "Wann: 5.2.2023 14:00\n\r" +
                    "Wo: Scala Club Opladen, Uhlandstraße 9, 51379 Leverkusen-Opladen\n\r" +
                    "Einlass: 13:00",
                files: [{
                    title: "Kindersitzung 2023",
                    src: "files/Kindersitzung20230205.pdf"
                }]
            },
            {
                title: "Abend unter Freunden 2023",
                image: "images/Feet20221121T0741.png",
                content: "Auch im Jahr 2023 laden wir wieder herzlich zu unserem 'Abend unter Freunden' ein!\n\r\n\r" +
                    "Wann: 28.1.2023 19:00\n\r" +
                    "Wo: Stadthalle Bergisch Neukirchen, Wuppertalstraße 8, 51381 Leverkusen-Bergisch Neukirchen\n\r" +
                    "Einlass: 18:00",
                files: [{
                    title: "Abend unter Freunden 2023",
                    src: "files/AbendUnterFreunden20230128.pdf"
                }]
            },
            {
                title: "Herrensitzung 2023",
                image: "images/Feet20221121T0741.png",
                content: "Verpasst auch nicht unsere 'Herrensitzung' im Februar 2023!\n\r\n\r" +
                    "Wann: 12.2.2023 15:00\n\r" +
                    "Wo: Im Festzelt am Opladener Marktplatz\n\r" +
                    "Einlass: 13:30",
                files: [{
                    title: "Herrensitzung 2023",
                    src: "files/Herrensitzung20230212.pdf"
                }]
            },
            {
                title: "Oktoberfest der KG Neustadtfunken 2022",
                image: "images/Oktoberfest2022.jpeg",
                content: "In traditioneller Tracht und zu bayrischen Schmankerl und leckerem Oktoberfestbier wollen und vorallem dürfen wir endlich wieder mit Euch zusammen schunkeln, singen und schwaden.\n\r\n\r" +
                    "Wann: 02.10.22 ab 15 Uhr\n\r" +
                    "Wo: Im Funkenkeller, 51379 Leverkusen",
                files: [{
                    title: "Oktoberfest und weitere Termine im November",
                    src: "files/Termine NSF 2022.pdf"
                }]
            },
            {
                title: "Sommerfest der KG Neustadtfunken 2022",
                image: "images/RZ_PlakatSommerfestA3.png",
                content: "Wann: 20.08.22 ab 14 Uhr\n\r" +
                    "Wo: Gelände der Steppenwölfe in der Daimlerstr. 2b, 51381 Leverkusen",
                files: [{
                    title: "RZ_Plakat Sommerfest A3",
                    src: "files/RZ_Plakat Sommerfest A3.pdf"
                }]
            },
            {
                title: "Mitteilung zum Jahreswechsel 2021/2022",
                image: "images/AbgesagteVeranstaltungen.jpg",
                content: "Leider müssen wir euch so kurz vorm Jahreswechsel noch etwas mitteilen." +
                    "\n\rDie traurige Vorahnung ist nun Gewissheit. Auch wir sagen sämtliche Sitzungen (Abend unter Freunden, Kindersitzung, Herrensitzung) im Frühjahr 2022 ab! Diese Entscheidung ist zum aktuellen Zeitpunkt die einzig Vernünftige." +
                    "\n\rEs ist mit Worten nicht zu beschreiben, wie sehr uns dies schmerzt. Unser Karneval fehlt uns, ihr fehlt uns!" +
                    "\n\rWir glauben, dass das neue Jahr auch neue Hoffnung bringt und wir auch diese Situation wieder einmal gemeinsam meistern werden." +
                    "\n\rBis dahin bleibt gesund und passt auf euch auf! 😘P.S.: Unsere „Kreativwerkstatt” arbeitet fleißig an Ideen - also haltet Ohren und Augen offen! 😉."
            },
            {
                title: "Sessionseröffnung am 11.11.21",
                content:
                    "Nach einer unendlich langen Zeit der Stille freuen wir uns, dass es endlich im Funkenkeller wieder laut wird. So laden wir Euch alle voller Freude zur Sessionseröffnung am 11.11 ab 18h in den Funkenkeller ein." +
                    "\n\nUm die Zeit unbeschwert genießen zu können, dürfen wir eure und unsere Gesundheit und Sicherheit nicht außer Acht lassen. Wir bitten Euch um Verständnis, dass wir die Veranstaltung unter Einhaltung der 2-G-Regelung (Geimpft oder Genesen) durchführen wollen. Minderjährige Teilnehmer sind von dieser Regelung aktuell ausgenommen, müssen jedoch ihren Schülerausweis oder einen negativen Corona Test bei sich tragen. Kinder, die noch nicht schulpflichtig sind, sind von einer Testpflicht befreit."

            },
            {
                title: "Mitsingabend am 26.11.21",
                content:
                    "So freuen wir uns sehr, Euch am 26.11.21 zu unserem traditionellen Mitsingabend ins Stilbruch (Augustastrasse 5 in Opladen) einzuladen. Das Stilbruch öffnet bereits um 19 Uhr, Start des Mitsing-Konzertes ist 20 Uhr. Im Stilbruch könnt ihr lecker essen und trinken und bekannter Weise ist beides für eine geölte Stimme sehr wichtig. Auch hier müssen wir Euch auf die 2-G-Regelung aufmerksam machen, da nur so die Durchführung einer solchen Veranstaltung umsetzbar ist."

            },
            {
                title: "Das Kinderprinzenpaar 2021/22",
                content:
                    "In der kommenden Session werden die Jecken von Jana I. und Jan-Peter I. regiert." +
                    "\n\rPassend zum Opladener Sessionsmotto: „Träume werden wahr“, ist mit der Ernennung zum Kinderprinzenpaar auch für die beiden ein Traum in Erfüllung gegangen.\n" +
                    "\n\rJana (Neugebauer) ist 13 Jahre alt und besucht die 6. Klasse der Rudolph- Steiner Schule in Remscheid. Jana tanzt schon seit vielen Jahren bei den Pänz vom Rosenhügel und liebt es in rot-weiß Frohsinn zu verbreiten. Neben dem Tanzen ist sie am liebsten mit ihren Pferden unterwegs." +
                    "\n\rJan-Peter (Eul) ist ebenfalls 13 Jahre alt und geht auf das Freiherr-von-Stein-Gymnasium in die 6. Klasse. Jan-Peter hat, wortwörtlich, Karneval im Blut: Sein Uropa, Günter Steingass, war 1948 der erste Prinz Karneval in Leverkusen.  Jan-Peter spielt Tennis, Golf, Basketball und Gitarre."

            },
            {
                title: "Unsere Festschrift für die Session 2020 - 2021",
                content:
                    "Mit einem Klick auf den Button könnt Ihr euch unsere Festschrift für die Session 2020 - 2021 herunterladen." +
                    "\n\rViel Spaß beim Lesen und bleibt weiterhin Gesund!"
                ,
                files: [{
                    title: "Festschrift 2020 - 2021",
                    src: "files/Festschrift NSF 2020-2021.pdf"
                }]
            },
            {
                title: "Liebe Freunde!",
                content:
                    "In diesem Jahr haben wir wenig Vorsätze:" +
                    "\n\rWir wollen weder an Gewicht verlieren, noch uns gesünder ernähren, noch mehr Sport treiben oder uns von irgendwelchen Lastern befreien." +
                    "\n\rWir wollen nur Euch zurück!" +
                    "\n\rWir wollen endlich wieder mit Euch gemeinsam im Funkenkeller feiern, gemeinsam schunkeln, uns umarmen, Lieder singen, ohne Abstand oder Plexiglasscheibe an der Theke stehen und zusammen tanzen."

            }
        ]);
    }
}

export default fakeNewsService;