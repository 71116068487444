import moment from "moment";

const fakeAppointmentsService = {
    dateFormat: "DD.MM.YYYY",
    timeFormat: " HH:mm",
    getAppointments: async function () {
        return Promise.resolve([
            {
                title: "Jünemann's Ovend",
                date: moment("25.01.2025 17:30", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Stonn op un Danz (AUSVERKAUFT!)",
                date: moment("02.11.2024", this.dateFormat),
                canceled: false
            },
            {
                title: "Rosenmontag im Funkenkeller",
                date: moment("03.03.2025 14:00", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Sommerfest auf dem Gelände der Steppenwölfe",
                date: moment("26.08.2023", this.dateFormat),
                canceled: false
            },
            {
                title: "Oktoberfest im Funkenkeller",
                date: moment("2.10.2023", this.dateFormat),
                canceled: false
            },
            {
                title: "Sessionseröffnung im Funkenkeller",
                date: moment("11.11.2024 18:00", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Mitsing Abend Im Stilbruch Opladen",
                date: moment("22.11.2024 19:00", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Feldmesse in der St. Elisabeth Kirche",
                date: moment("08.02.2025 18:00", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Kindersitzung im Scala Club Opladen",
                date: moment("09.02.2025 13:00", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Abend unter Freunden in der Stadthalle Bergisch Neukirchen",
                date: moment("27.01.2024", this.dateFormat),
                canceled: false
            },
            {
                title: "Herrensitzung im Festzelt am Marktplatz Opladen",
                date: moment("23.02.2025 13:30", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "Weiberfastnacht im Funkenkeller",
                date: moment("27.02.2025 17:00", this.dateFormat + this.timeFormat),
                canceled: false
            },
            {
                title: "After Zoch Party im Funkenkeller",
                date: moment("12.02.2024", this.dateFormat),
                canceled: false
            }
        ])
    }
}

export default fakeAppointmentsService;