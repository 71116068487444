import React from "react";
import fakeProfilesService from "../services/fakeProfilesService";
import fakeContentService from "../services/fakeContentService";
import VisitcardComponent from "./VisitcardComponent";
import ContentImage from "./StateLessComponents/ContentImage";
import ContentImageBlockquote from "./StateLessComponents/ContentImageBlockquote";

class CorpsComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            trainersCorps: [],
            commanderCorps: [],
            bookingPartner: []
        };
    }

    async componentDidMount() {
        const trainersCorps = await fakeProfilesService.getProfiles({ filter: p => { return p.trTC === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const commanderCorps = await fakeProfilesService.getProfiles({ filter: p => { return p.cTC === "x" }, orderBy: (a, b) => a.lastname.localeCompare(b.lastname) });
        const bookingPartner = await fakeProfilesService.getProfiles({ filter: p => { return p.bookTC === "x" } });
        const content = await fakeContentService.getCorpsContent();

        trainersCorps?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        commanderCorps?.forEach(profile => {
            profile.phone = null;
            profile.email = null;
        });

        this.setState({
            trainersCorps,
            commanderCorps,
            bookingPartner,
            content
        });
    }

    render() {
        const { trainersCorps, commanderCorps, bookingPartner, content } = this.state;

        return (
            <div>
                <h1 className="kgnsf-header">Unser Traditionscorps</h1>

                <ContentImage image={"/images/neustadtfunken/traditionscorps/Traditionscorps.png"} label={"Unser Traditionscorps 2021 / 2022"} />

                <ContentImageBlockquote imageSrc={commanderCorps[0]?.picture} imageLabel={"Kommandant, " + commanderCorps[0]?.name} content={content} />

                <div className="row">
                    <div className="col">
                        {trainersCorps && <VisitcardComponent title="Trainerin: Traditionscorps" profiles={trainersCorps}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {commanderCorps && <VisitcardComponent title="Kommandant: Traditionscorps" profiles={commanderCorps}></VisitcardComponent>}
                    </div>
                    <div className="col">
                        {bookingPartner && <VisitcardComponent title="Bei Fragen / Buchung" profiles={bookingPartner}></VisitcardComponent>}
                    </div>
                </div>
            </div >
        )
    }
}

export default CorpsComponent