import React from "react";
import { Link, useLocation } from "react-router-dom";

const NavComponent = () => {
    const location = useLocation();

    window.scrollTo(0, 0);

    return (
        <nav className="navbar navbar-expand-sm navbar-dark bg-dark sticky-top">
            <div className="container-fluid">
                <Link className="navbar-brand" to="/home">
                    <img alt="" src={"/images/NeustadtFunkenWappenHomeButton.png"} />
                </Link>
                <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span className="navbar-toggler-icon"></span>
                </button>
                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                        <li className="nav-item">
                            <Link className={location.pathname === "/home" ? "nav-link active" : "nav-link"} to="/home">Home</Link>
                        </li>
                        <li className="nav-item dropdown">
                            <a className="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                Neustadtfunken
                            </a>
                            <ul className="dropdown-menu dropdown-menu-dark" aria-labelledby="navbarDropdown">
                                <li className="nav-item">
                                    <Link className={location.pathname === "/pawns" ? "dropdown-item active" : "dropdown-item"} to="/pawns">Pänz vom Rosenhügel</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={location.pathname === "/pawnspair" ? "dropdown-item active" : "dropdown-item"} to="/pawnspair">Kinderprinzenpaar</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={location.pathname === "/garde" ? "dropdown-item active" : "dropdown-item"} to="/garde">Tanzgarde</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={location.pathname === "/corps" ? "dropdown-item active" : "dropdown-item"} to="/corps">Traditionscorps</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={location.pathname === "/pair" ? "dropdown-item active" : "dropdown-item"} to="/pair">Tanzpaar</Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="nav-item">
                                    <Link className={location.pathname === "/pedastrians" ? "dropdown-item active" : "dropdown-item"} to="/pedastrians">Fußgruppe</Link>
                                </li>
                                <li>
                                    <hr className="dropdown-divider" />
                                </li>
                                <li className="nav-item">
                                    <Link className={location.pathname === "/directors" ? "dropdown-item active" : "dropdown-item"} to="/directors">Vorstand</Link>
                                </li>
                            </ul>
                        </li>
                        <li className="nav-item">
                            <Link className={location.pathname === "/history" ? "nav-link active" : "nav-link"} to="/history">Historisches</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={location.pathname === "/cellar" ? "nav-link active" : "nav-link"} to="/cellar">Funkenkeller</Link>
                        </li>
                        <li className="nav-item">
                            <Link className={location.pathname === "/imprint" ? "nav-link active" : "nav-link"} to="/imprint">Impressum</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
    );
}

export default NavComponent;