const fakeContentService = {
    getCorpsContent: async function () {
        return Promise.resolve([
            "Das Traditionscorps der KG Neustadtfunken versteht sich als ein traditionelles Offizierscorps, dass es sich zur Aufgabe gemacht hat, Frohsinn und Freude in die 5. Jahreszeit zu bringen. Sie pflegen das Brauchtum durch ihre historischen Uniformen, traditionellem Regelwerk (Beförderungen, Abzeichen, Dienstgrade) und ihrer Liebe und Hingabe zum Opladener Karneval.",
            "Im Jahr 1998 gründetet eine Gruppe gestandener Karnevalisten das Traditionschorps der Gesellschaft, da es ihnen eben nicht mehr ausreichte, den Fastelovend nur zu feiern, sie wollten aktiv an ihm teilnehmen. Lotti Bölker, eine ehemalige Marie der Neustadtfunken, konnte schnell zur 1. Trainerin rekrutiert werden. Zu Beginn waren die Ziele noch sehr bescheiden, lediglich „Stippeföttchen“ sollte auf den Brettern der Welt zum Besten gebracht werden. Doch Lotti erkannte schnell Potential in ihren Offizieren und so war schnell der beliebte Klassiker „Der Funkentanz“ geboren. In den folgenden Jahren lösten sich die Trainerinnen ab, von denen jede einzelne zur tänzerischen Weiterentwicklung beigetragen hat. Meike Mönchmeyer-Göddertz bringt nun seit mehreren Jahren bereits ihre Jungs ins Schwitzen und holt Ungeahntes aus ihnen heraus. Vor über 20 Jahren hätte noch niemand gedacht, dass das Traditionscorps der KG Neustadtfunken sich solche Schrittkombinationen in einem solchen Tempo merken oder so hoch die Beine werfen könnte. Seit mehreren Jahren nun tanzen die Männer nicht mehr alleine mit ihrer Marie, sondern heben auch die Tanzgarde in ungeahnte Höhen. Die gemeinsamen Tänze von Tanzpaar, Tanzgarde und Traditionscorps macht die KG Neustadtfunken einmalig.",
            "Neben dem Training trifft sich das Traditionscorps mehrmals jährlich zum Stammtisch, Oktoberfest, Funkentour und jedem nur denkbaren Event. Ganz nach dem Motto: 'Man(n) sollte die Feste feiern wie sie fallen.'"
        ]);
    },
    getGardeContent: async function () {
        return Promise.resolve([
            "Es begab sich vor nicht allzu langer Zeit in einem nicht allzu fernen Land. In diesem neuen Land, auch als Neustadt bekannt, lebten Indianer, Piraten, Clowns, Prinzessinnen, Cowboys und noch viele andere Lebewesen glücklich und in Frieden zusammen. Unter ihnen lebten auch sogenannte Gardemädels, die sich zur Aufgabe machten, dem Volk Freude und Frohsinn zu bringen. Sie trainierten hart, um anderen eine schöne, jecke Zeit zu verschaffen und den Alltag vergessen zu lassen. Für diese Gardemädels war dies kein Beruf, sondern vielmehr eine Berufung. Ihr Lächeln war ehrlich, sie liebten es nicht nur, gemeinsam auf den Bühnen des Landes zu stehen, sondern auch hinter dem Vorhang Zeit zu verbringen. Angeführt wurden sie von ihrer 'ChefDompteurin' Meike Göddertz, die sich jede Woche neu der Herausforderung stellte, einem Flozirkus voller Bäuerinnen grazile Tanzschritte beizubringen.",
            "Jede Geschichte eines Gardemädels hat einen Anfang und ein Ende, und zwar stets ein Happy End. Denn wenn sie nicht gestorben sind (bzw. schwanger geworden sind), dann leben sie noch heute und erheben ihr Glas 'auf alles was schön ist, auf sich'.",
            "Seit über 20 Jahren können die Neustadtfunken stolz auf eine Tanzgarde zurückblicken. Eine Tanzgarde mit motivierten und engagierten Mädels, die es sich zur Aufgabe gemacht haben, das Publikum stets zu überraschen. Sie trainieren über neun Monate im Jahr, ca. 3 Stunden die Woche. Sie stellen ihr Privatleben oft hinten an, investieren ihr Gehalt in Magnesiumtabletten, um den Muskelkater zu überleben oder vermeiden monatelang den Frisör, um lange Zöpfe heranzuzüchten. Aktuell strahlen 16 Mädels zwischen 17 und 32 Jahren um die Wette und schaffen es innerhalb weniger Sekunden, das Publikum für sich zu gewinnen.",
            "Natürlich vertritt jeder die Meinung, Teil des besten Teams auf Erden zu sein, doch bei mir ist es die Wahrheit! Ich bin sehr dankbar für jede Einzelne, ihr macht die Neustadtfunken zu einer Familie für mich!"
        ]);
    },
    getPawnsContent: async function () {
        return Promise.resolve([
            "„Rut un wiess, wie lieb ich dich“(Bläck Fööss), dieses Motto haben bereits schon unsere ganz Kleinen, unsere Pänz vom Rosenhügel, der Neustadtfunken ganzer Stolz, im Blut.",
            "",
            "Mit insgesamt 32 Kindern, 5 Jungs und 27 Mädchen sind sie eine der größten Kinder- und Jugendtanzcorps in Leverkusen. ",
            "Um vom 11.11 bis Aschermittwoch die Herzen aller Zuschauer zu verzaubern, zeigen sie beim wöchentlichen Training vollen Eifer.",
            "Für unsere Pänz gibt es kein schöneres Gefühl, als von der Schule zu kommen, die roten Tanzstiefel zu schnüren, die Zöpfe zu flechten bzw. das Haar zu bändigen und die geliebte Uniform anzuziehen, um zu den Auftritten zu fahren und darzubieten, was man das ganze Jahr gelernt hat. ",
            "",
            "Unsere „Kleinen“, im Alter zwischen 4 und 11 Jahren, trainieren jeden Mittwoch von 17:00- 18:00 Uhr in unserem heimischen Funkenkeller. Und leben mit großer Freude ihre Liebe zum Karneval in ihren Tänzen aus. Begleitet werden sie hierbei von Petra Hebbel, die selber schon seit Jahrzehnten ein fester Bestandteil der Neustadtfunken ist. Unterstützt wird sie dieses Jahr zusätzlich noch von Loredana und Sarah Ziegler.",
            "",
            "Unsere „großen“ Pänz  trainieren ebenfalls mittwochs  von 18:00-19:30. Trainiert werden sie von Anne Berghöfer-Weis, die selbst ihre Anfänge bei den Pänz vom Rosenhügel hatte. In der Jugend trainiert alles ab 11 Jahren bis zum Eintritt in das Traditionscorps oder in die Tanzgarde.",
            "",
            "Seit nun 25 Jahren sind die Pänz ein fester Bestandteil der Neustadtfunken, und für niemanden mehr wegzudenken.  Und wir hoffen natürlich, dass nochmal mindestens  11 x 25 Jahre dazu kommen.",
            "In diesem Jahr wollen wir gemeinsam mit allen Jecken und unseren Pänz:  „Tanzen unterm Tisch solange wie geht, und singen dabei bis es morgen wird!“ ",
            "In diesem Sinne auf unsere Pänz vom Rosenhügel, von Hätze, ein dreifaches Alaaf!"
        ]);
    },
    getPawnsPairContent: async function () {
        return Promise.resolve([
            "Das 67. Kinderprinzenpaar der KG Neustadtfunken steht in den Startlöchern und wir freuen uns sehr auf eine grandiose Session mit den beiden.",
            "",
            "Linnéa (Kalume) ist 11 Jahre alt, lebt in Opladen und besucht die 6.Klasse der Marienschule. Bereits seit 3 Jahren gehört sie den Pänz vom Rosenhügel an und liebt es mit den anderen kleinen Tänzern, gemeinsam die Bühnen zu erobern. Auch wenn es ihr schwer fällt, in diesem Jahr ihre Tanzstiefel im Schrank stehen lassen zu müssen, überwiegt ihre Freude, die Opladener Mini-Jecken als Prinzessin vom Rosenhügel regieren zu dürfen. Neben dem Tanzen im Karneval schwingt sie ihre Hüften noch beim TSG Leverkusen (Latein und Standardtanz) und liebt es, ihre Zeit auf dem Rücken der Pferde zu verbringen. Bei Linnéa sind die Neustadtfunken fester Bestandteil ihrer Familie: Neben ihrer Mutter, die Teil der Fußgruppe ist, tanzt noch ihr (Paten-)Onkel Sebastian Newiadomsky bereits seit Jahren im Traditionscorps und unterstützt den Verein zusätzlich noch als Senator und Schriftführer. Auch ihre Tante Charlotte Newiadomsky ist kein ungeschriebenes Blatt in der Gesellschaft: Auch sie ist Teil der Fußgruppe und als Zeugwartin für die Uniformen aller Tänzer zuständig. ",
            "",
            "An ihrer Seite dürfen wir voller Stolz Prinz Lennard I. vorstellen. ",
            "Lennard (Rehn) ist 10 Jahre alt und besucht die 5. Klasse der Johannes-Löh Gesamtschule in Burscheid. Er liebt es am Computer „zu daddeln“ und Musik zu hören. Diesen Ausgleich braucht er auch, dann seine absolutes Lieblingshobby ist der Fußball, und diesem kommt er tatkräftig beim TG Hilden nach.",
            "Seine Eltern sind bei den Neustadtfunken bzw. in der Neustadt ebenfalls bekannt wie „bunte Hunde“. Sein Vater, Marcus Rehn, führt bereits seit Jahren das Stilbruch in Opladen und unterstützt die Gesellschaft seit 2016 als Senator. Auch seine Frau Corinna ist aus Opladen nicht mehr weg zu denken und wir freuen uns mit der gesamten Familie, zu der auch Tochter Emilia gehört, noch öfter feiern zu dürfen. Fast genauso so sehr freuen wir uns darauf, endlich wieder eine „Kinderhofburg“ mitten in der Neustadt zu haben und können es kaum erwarten, diese mit unserem Kinderprinzenpaar einzuweihen.",
            "",
            "Auf eine großartige Session, mit einem wundervollen Kinderprinzenpaar!",
            "Auf Prinzessin Linnéa I. und Prinz Lennard I."
        ]);
    },
    getPairContent: async function () {
        return Promise.resolve([
            "Das Aushängeschild der Gesellschaft und der ganze Stolz des Vereins ist unser Tanzpaar.",
            "Als Teil des Traditionscorps verzaubern sie gemeinsam mit den Jungs und Mädels jedes Publikum. In den vergangenen Jahren hatten wir viele Veränderungen bzw. mussten einige Mariechen und Tanzoffiziere ziehen lassen, damit sie sich auf andere Dinge konzentrieren konnten. So auch in diesem Jahr. Nach nur drei Sessionen musste wir uns Aschermittwoch von unserer lieb gewonnenen Jenny verabschieden. Jenny erwartet ihr erstes Kind und wir freuen uns riesig auf unseren neuen Mini-Offizier.<br />",
            "Zum Glück konnte sich bereits nach einem Jahr als Tanzoffizier ihr Ehemann Flo Hessenbruch es sich nicht mehr vorstellen, seine Stiefel ebenfalls an den Nagel zu hängen. Flo hat nach seinen Lehr- und Wanderjahren in anderen Vereinen seine  karnevalistische Heimat bei uns gefunden, worüber wir uns unendlich freuen. Neben dem Tanzen verbringt Flo seine Zeit beim Gala-Bau, mit seiner Frau Jenny oder auf dem Motorrad. Er ist durch und durch „karnevalsbekloppt“ und fegt bereits seit über 10 Jahren über die Bühnen dieser Welt.<br />",
            "Jahrelang war Jenny an seiner Seite und eine andere Marie konnte er sich kaum vorstellen, bis er Alina kennen gelernt hat. Alina begeistert bereits schon seit fast einem Jahrzehnt die Jecken, erst als ein Teil der Pänz vom Rosenhügel und anschließend bei der Tanzgarde. Es war schon immer ihr Traum, einmal Marie der Neustadtfunken zu werden, und wir freuen uns unendlich, ihr diesen Wunsch zu erfüllen. Die beiden wachsen jede Woche immer mehr zusammen. Sie beim gemeinsamen Training zu beobachten, macht einfach großen Spaß. Ohne sich wirklich zu suchen, haben die beiden sich doch gefunden.<br />",
            "Wir freuen uns auf eine tolle Session!"
        ]);
    },
    getPedestriansContent: async function () {
        return Promise.resolve([
            "Die Fußgruppe der KG Neustadtfunken ist ein weiterer Zweig der Gesellschaft, der es sich zur Aufgabe gemacht hat, den Rosenmontag noch etwas bunter zu machen. Jedes Jahr aufs Neue lassen sie gemeinsam ihrer Kreativität freien Raum um sich Kostüme für den Zug zu überlegen und diese dann selbst zu nähen. Niemand bestimmt etwas, sondern alle Ideen sind willkommen und am Ende entscheiden alle gemeinsam. Bereits als Chinesen, Hofdamen, Gaukler oder Postjungen machten sie den Rosenmontagszug unsicher und überschütteten die Jecken mit einer Flut aus Kamellen.",
            "Die Fußgruppe setzt sich aus einer bunten Mischung aus ehemaligen Tänzern, Pänzeltern, Freunden der Gesellschaft, Senatoren oder Geschwistern zusammen. Eben diese vielseitige Konstellation macht die Fußgruppe so besonders und einzigartig.",
            "Wir sind unendlich stolz auf diese tolle Truppe, die uns und vor allem die Jecken am Straßenrand jedes Jahr wieder verzaubern. Wir als Gesellschaft wollen diesen „Zweig“ nie wieder missen und danken allen für ihre Arbeit, Zeit, Mühe und Liebe!",
            "Wer auch Lust hat, Teil dieser tollen Gruppe zu werden, kann sich jederzeit bei uns melden! Wir freuen uns über jeden Jecken, der mit uns zusammen den Rosenmontagszug unsicher macht."
        ]);
    }
};

export default fakeContentService;